@font-face {
  font-family: 'GothamBlack';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./fonts/GothamPro-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'GothamRegular';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/GothamPro.woff2') format('woff2');
}

@font-face {
  font-family: 'GothamLight';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./fonts/GothamPro-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'GothamMedium';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./fonts/GothamPro-Medium.woff2') format('woff2');
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiListItemIcon-root	{min-width: 40px !important;}

 